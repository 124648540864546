if ('define' in window) {
define("discourse/theme-18/discourse/initializers/theme-field-140-common-html-script-1", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(18);
  const themePrefix = key => `theme_translations.18.${key}`;
  var _default = _exports.default = {
    name: "theme-field-140-common-html-script-1",
    after: "inject-objects",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8", api => {
        const user = api.getCurrentUser();
        const body = document.querySelector('body');
        if (user) {
          user.groups.map(g => `group-${g.name}`).forEach(g => body.classList.add(g));
        }
      });
    }
  };
});
}
